import { useEffect, useState } from "react";
import { useQueryContext } from "../../core/contexts/query";
import Text from "../../core/utils/Text";
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles";
import BasicModalActionButton from "../common/buttons/BasicModalActionButton";
import Input from "../common/input/Input";
import { BiPlus, BiX } from "react-icons/bi";
import { useDeleteResultResponse, useGetResultResponses } from "../../core/services/react-query/questions";


const StoreResultResponsesModal = ({ onSubmit, onCancel, defaultData }) => {
    const { setToast } = useQueryContext();
    const { mutate } = useDeleteResultResponse()
    const [resultResponses, setResults] = useState([{ id: "", min_score: 0, max_score: 0, response: "" }]);

    const addResultResponse = () => {
        setResults((prev) => [
            ...prev,
            { id: "", min_score: 0, max_score: 0, response: "" },
        ]);
    };

    const removeResultResponse = (index) => {
        if (resultResponses.length > 1) {
            if (defaultData && resultResponses[index].id) {
                mutate(resultResponses[index].id)
            }
            setResults((prev) => prev.filter((_, i) => i !== index));
        }
    };

    const onResultChange = (value, index, field) => {
        setResults((prev) =>
            prev.map((result, i) =>
                i === index ? { ...result, [field]: field === 'min_score' || field === 'max_score' ? Number(value) : value } : result
            )
        );
    };

    const onSubmitClicked = () => {
        const valid = resultResponses.every(
            (result) =>
                result.min_score && result.max_score && result.response
        );
        if (valid) onSubmit(resultResponses);
        else
            setToast({
                isError: true,
                show: true,
                message: "fill-input-errors",
            });
    };

    useEffect(() => {
        if (defaultData && defaultData.length) {
            const defaultResults = defaultData.map(data => {
                return { id: data.id, min_score: data.min_score, max_score: data.max_score, response: data.response }
            })

            setResults(defaultResults)
        }
    }, [defaultData])

    return (
        <CFlex fw align="flex-start">
            <Background>
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid={`define-final-response`} />
                    </DText>
                </Flex>
                <CMargin margin="10px" />
                {resultResponses.map((result, index) => (
                    <div key={index} style={{ marginBottom: "15px", width: '100%' }}>
                        <Flex align="center" justify='flex-start' margin='0' fw>
                            {
                                resultResponses.length > 1 &&
                                <div style={{ cursor: 'pointer' }} onClick={() => removeResultResponse(index)}>
                                    <BiX size={24} color="#c33" />
                                </div>
                            }
                            <Input
                                width="22%"
                                value={result.min_score}
                                label={"min-score"}
                                onInputChange={(v) => onResultChange(v, index, "min_score")}
                                number
                                margin="0"
                            />
                            <Input
                                width="22%"
                                value={result.max_score}
                                label={"max-score"}
                                onInputChange={(v) => onResultChange(v, index, "max_score")}
                                number
                                margin="0"
                            />
                            <Input
                                width="55%"
                                value={result.response}
                                label={"final-response"}
                                onInputChange={(v) => onResultChange(v, index, "response")}
                                margin="0"
                            />
                        </Flex>
                    </div>
                ))}
                <div style={{ cursor: 'pointer' }} onClick={addResultResponse}>
                    <BiPlus size={24} color="#43ee00" />
                </div>
                <CMargin margin="10px" />
                <BasicModalActionButton
                    onSubmit={onSubmitClicked}
                    onCancel={onCancel}
                />
            </Background>
        </CFlex >
    );
};

export default StoreResultResponsesModal;
