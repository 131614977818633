import { useState } from "react";
import { useQueryContext } from "../../core/contexts/query";
import Text from "../../core/utils/Text";
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles";
import BasicModalActionButton from "../common/buttons/BasicModalActionButton";
import Input from "../common/input/Input";
import Select from "../common/dropdown/Select";
import { BiPlus, BiX } from "react-icons/bi";
import { useEffect } from "react";

const answersLabelOptions = ["very_low", "low", "normal", "high", "very_high"];

const CreateQuestionModal = ({ onDeleteAnswer, onSubmit, onCancel, question }) => {
    const { setToast } = useQueryContext();

    const [answers, setAnswers] = useState([{ id: "", question_id: question.id.toString(), label: "", score: "" }]);

    const addAnswer = () => {
        setAnswers((prev) => [
            ...prev,
            { id: "", question_id: question.id.toString(), label: "", score: "" },
        ]);
    };

    const removeAnswer = (index) => {
        if (answers.length > 1) {
            if (question && question.responses.length && answers[index].id) {
                onDeleteAnswer(answers[index].id)
            }
            setAnswers((prev) => prev.filter((_, i) => i !== index));
        }
    };

    const onAnswerChange = (value, index, field) => {
        setAnswers((prev) =>
            prev.map((answer, i) =>
                i === index ? { ...answer, [field]: field === 'score' ? Number(value) : value } : answer
            )
        );
    };

    const onSubmitClicked = () => {
        const valid = answers.every(
            (answer) =>
                answer.label && answer.score
        );
        if (valid) onSubmit(answers);
        else
            setToast({
                isError: true,
                show: true,
                message: "fill-input-errors",
            });
    };

    useEffect(() => {
        if (question && question.responses.length) {
            const defaultA = question.responses.map(res => {
                return {
                    id: res.id.toString(), question_id: res.question_id.toString(), label: res.label, score: res.score
                }
            })
            setAnswers(defaultA)
        }
    }, [question])

    return (
        <CFlex fw align="flex-start">
            <Background>
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid={`define-answers`} />
                    </DText>
                </Flex>
                <CMargin margin="10px" />
                {answers.map((answer, index) => (
                    <div key={index} style={{ marginBottom: "15px", width: '100%' }}>
                        <Flex align="center" fw>
                            {
                                answers.length > 1 &&
                                <div style={{ cursor: 'pointer' }} onClick={() => removeAnswer(index)}>
                                    <BiX size={24} color="#c33" />
                                </div>
                            }
                            <Input
                                width="100%"
                                value={answer.score}
                                label={"score"}
                                onInputChange={(v) => onAnswerChange(v, index, "score")}
                                number
                                margin="0"
                            />
                            <Select
                                value={answer.label}
                                placeHolder={"label"}
                                onValueChange={(idx) =>
                                    onAnswerChange(answersLabelOptions.filter(opt => answers.every(ans => opt !== ans.label))[idx], index, "label")
                                }
                                options={answersLabelOptions.filter(opt => answers.every(ans => opt !== ans.label))}
                                dropdownWidth="230px"
                                direction="down"
                            />
                        </Flex>
                    </div>
                ))}
                {
                    answers.length !== answersLabelOptions.length &&
                    <div style={{ cursor: 'pointer' }} onClick={addAnswer}>
                        <BiPlus size={24} color="#43ee00" />
                    </div>
                }
                <CMargin margin="10px" />
                <BasicModalActionButton
                    onSubmit={onSubmitClicked}
                    onCancel={onCancel}
                />
            </Background>
        </CFlex >
    );
};

export default CreateQuestionModal;
